<template>
<div>
<app-bar title="平安成电智慧通行" />
  <div style="
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 55px);
">
  <div class="spinner" v-if="!hiddenLoading">
        <md-progress-spinner class="md-primary" :md-stroke="4"
          :md-diameter="50" md-mode="indeterminate"></md-progress-spinner>
        
      </div>
</div>
</div>
</template>

<script>
import AppBar from './AppBar.vue'

export default {
  name: 'LoadingPage',

  components: {
    AppBar
  },

  data () {
    return {
      loading: true,
      hiddenLoading: false,
    }
  },

   mounted () {
    document.title = '平安成电智慧通行'

    setTimeout(() => {
        this.$router.replace('./home')
    }, 1500)
  },

}
</script>

<style lang="less" scoped>

@keyframes run {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-200px);
  }
}

.spinner {
    transition: all ease .3s;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    transform: translateY(0);
  }


</style>